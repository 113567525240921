import { render, staticRenderFns } from "./ContractHistoryComponent.vue?vue&type=template&id=30faf1b6&scoped=true"
import script from "./ContractHistoryComponent.vue?vue&type=script&lang=js"
export * from "./ContractHistoryComponent.vue?vue&type=script&lang=js"
import style0 from "./ContractHistoryComponent.vue?vue&type=style&index=0&id=30faf1b6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30faf1b6",
  null
  
)

export default component.exports