<template>
  <v-dialog v-model="dialog" persistent width="1080" @click:outside="closeDialog()">
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-icon
            v-on="on"
            small
            color="neuter"
            class="mr-2 zoom__btn"
            @click="showDialog()"
          >
            mdi-progress-clock
          </v-icon>
        </template>
        <span>
          {{ $t("tokens.buttons.history") }}
          <span style="font-size: 12px; color: #aaaaaa">
            {{ $t("modules.name.TokenWallet") }}
          </span>
        </span>
      </v-tooltip>
    </template>
    <v-card>
      <v-overlay :opacity="0.9" :value="loading" absolute color="white">
        <p class="text--disabled text-end ma-0 pa-0 code-metamask">
          {{ $t("tokens.titles.waitingFor") }}
        </p>
        <v-img :src="require('@/assets/img/metamask-logo.svg')" contain />
        <v-progress-linear class="mt-1" color="#f5841f" indeterminate />
      </v-overlay>
      <v-card-title class="headline secondary white--text">
        {{ $t('tokens.titles.contractDialog') }}
      </v-card-title>
      <div class="pa-5">
        <v-data-table
          :search="search"
          :headers="headers"
          :no-data-text="$t('general.ui.not_data_found')"
          :no-results-text="$t('general.ui.not_search_found')"
          :items="contractHistory"
          :footer-props="{
            ...rowsPerPageItemsOptions,
            itemsPerPageText: $t('general.table.itemsPerPageText'),
            pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
          }"
        >
          <template v-slot:top>
            <v-row
              class="my-4 d-flex justify-space-between align-center align-content-center"
            >
              <v-col lg="4" md="4" sm="6" xl="4" cols="6">
                <v-text-field
                  onkeypress="return (event.charCode != 34)"
                  v-on:keyup.86="replaceSearch"
                  v-on:keyup.ctrl.86="replaceSearch"
                  dense
                  filled
                  rounded
                  color="primary"
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('general.titles.filter')"
                  :clearable="true"
                  single-line
                  hide-details
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col
                lg="8"
                md="8"
                sm="8"
                xl="8"
                cols="8"
                class="d-flex justify-end align-center align-content-center"
              >
                <v-btn-toggle
                  color="neuter"
                  :borderless="false"
                  v-model="value"
                  @change="searchHistory($event)"
                >
                  <v-btn value="GENERAL" small>
                      <span class="hidden-sm-and-down text-uppercase">{{
                          $t("tokens.buttons.general")
                        }}</span>
                  </v-btn>

                  <v-btn value="EMIT" small>
                      <span class="hidden-sm-and-down text-uppercase">{{
                          $t("tokens.buttons.emitted")
                        }}</span>
                  </v-btn>

                  <v-btn value="BURN" small>
                      <span class="hidden-sm-and-down text-uppercase">{{
                          $t("tokens.buttons.burned")
                        }}</span>
                  </v-btn>

                  <v-btn value="SEND" small>
                      <span class="hidden-sm-and-down text-uppercase">{{
                          $t("tokens.buttons.send")
                        }}</span>
                  </v-btn>

                  <v-btn value="SETTLE" small>
                      <span class="hidden-sm-and-down text-uppercase">{{
                          $t("tokens.buttons.settled")
                        }}</span>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.lot="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-on="on"
                  v-bind="attrs"
                  small
                  :href="`${amoyNet}/token/${contract.contractAddress}?a=${item.nftRef}`"
                  target="_blank"
                >
                  <p class="ma-0 pa-0">{{ item.lot }}</p>
                  <v-icon class="ml-2" small rigth
                    >mdi-link-variant</v-icon
                  >
                </v-chip>
              </template>
              <span>{{ $t('tokens.titles.nftHistory') }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.tx_hash="{ item }">
            <a
              class="d-flex justify-space-between align-center align-content-center accent--text"
              :href="`${amoyNet}/tx/${item.tx_hash}`"
              target="_blank"
            >
              <span>
                {{ item.tx_hash | truncate(10) }}
              </span>
            </a>
          </template>
          <template v-slot:item.action="{ item }">
            {{ $t(translateAction(item.action)) }}
          </template>
          <template v-slot:item.logDate="{ item }">
            <v-chip small>
              {{ new Date(item.logDate).toLocaleString() }}
            </v-chip>
          </template>
          <template v-slot:item.from="{ item }">
            <v-chip small>
              <span v-if="fetchAddressName(item.from)">
                {{ fetchAddressName(item.from) }}
              </span>
              <span v-else>
                {{ item.from | truncateAddress(7) }}
              </span>
            </v-chip>
          </template>
          <template v-slot:item.to="{ item }">
            <v-chip small v-if="['EMIT', 'BURN', 'SETTLE'].includes(item.action)">
              <v-icon left>mdi-file-document-outline</v-icon>
              <span v-if="fetchAddressName(item.to)">
                {{ fetchAddressName(item.to) }}
              </span>
              <span v-else>
                {{ item.to | truncateAddress(7)  }}
              </span>
            </v-chip>
            <v-chip small v-else>
              <v-icon left>mdi-account-outline</v-icon>
              <span v-if="fetchAddressName(item.to)">
                {{ fetchAddressName(item.to) }}
              </span>
              <span v-else>
                {{ item.to | truncateAddress(7)  }}
              </span>
            </v-chip>
          </template>
        </v-data-table>
      </div>
      <v-divider class="gray" />
      <v-card-actions class="px-8 py-6">
        <v-spacer></v-spacer>
        <v-btn small :disabled="loading" color="primary" elevation="0" class="t-bw-primary--text" @click="closeDialog()"
          >{{ $t('general.buttons.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";
import i18n from "@/plugins/i18n";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ContractHistoryComponent",

  props: {
    contract: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    rowsPerPageItemsOptions: {
      itemsPerPageOptions: [5, 15, 30, 50],
    },
    dialog: false,
    loading: false,
    search: null,
    value: "GENERAL",
    history: [],
    amoyNet: process.env.VUE_APP_AMOY_SCAN
  }),

  computed: {
    ...mapGetters({
      wallets: 'web3/GET_WALLETS',
      contractHistory: 'web3/GET_CONTRACT_HISTORY'
    }),
    headers() {
      return [
          {
            text: i18n.t('inventory.titles.date'), value: 'logDate', val: 'logDate'
          },
          {
            text: i18n.t('inventory.titles.action'), value: 'action', val: 'action'
          },
          {
            text: i18n.t('inventory.titles.lot'), value: 'lot', val: 'lot'
          },
          {
            text: i18n.t('inventory.titles.amount'), value: 'amount', val: 'amount', width: 150
          },
          {
            text: 'Tx/Hash', value: 'tx_hash', val: 'tx_hash'
          },
          {
            text: i18n.t('inventory.titles.from'), value: 'from', val: 'from'
          },
          {
            text: i18n.t('inventory.titles.to'), value: 'to', val: 'to'
          },
        ]
    }
  },

  methods: {
    ...mapActions({
      fetchTrackingByContract: 'web3/FETCH_TRACKING_BY_CONTRACT'
    }),
    async searchHistory(event) {
      this.loading = true
      await this.fetchTrackingByContract([event, this.contract.id]).finally(() => {
        this.loading = false
      })
    },
    async showDialog() {
      this.dialog = true
      await this.searchHistory(this.value)
    },
    translateAction(action) {
      switch (action) {
        case "SEND":
          return 'tokens.text.send'
        case "EMIT":
          return 'tokens.text.emit'
        case "BURN":
          return 'tokens.text.burn'
        case "RECEIVED":
          return 'tokens.text.received'
        case "SETTLE":
          return 'tokens.text.settle'
      }
    },
    fetchAddressName(address) {
      const w = _.find(this.wallets, { address: address })
      return w ? w.name : null
    },
    closeDialog() {
      this.expanded = []
      this.dialog = false
    },
    replace(event){
      event.currentTarget.value =  event.currentTarget.value.toString().replaceAll('"','')
    },
    replaceSearch(event){
      this.search =  event.currentTarget.value.toString().replaceAll('"','')
    },
  }
}
</script>

<style scoped>
.v-chip {
  border-radius: 0.3em !important;
  font-size: 12px !important;
}

.v-chip .v-icon {
  font-size: 14px !important;
}

.v-chip.success {
  border-radius: 0.3em !important;
  color: rgb(2, 54, 75) !important;
  background-color: rgba(91, 217, 255, 0.137) !important;
  font-weight: 800;
}

span.v-chip__content {
  display: flex !important;
  justify-content: space-between !important;
}

a {
  text-decoration: none;
}
</style>
